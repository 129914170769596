import React from 'react';
import { Box, Button, Image, Text, VStack, Heading, HStack } from '@chakra-ui/react';

const LandingPage = () => {
  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      height="100vh" 
      bg="gray.100" 
      p={4}
    >
      <VStack spacing={3} alignItems="center">
        <Image
          borderRadius="full"
          boxSize="250px"
          src="./kenji-oyasu.png"  // Replace with the actual profile image URL
          alt="Modern Med Chicago"
        />
        
        <Heading as="h1" size="lg" textAlign="center">
          Modern Med Chicago
        </Heading>
        
        <Text fontSize="lg" textAlign="center" maxWidth="600px">
          Welcome to Modern Med Chicago. We provide world-class concierge and recovery services tailored to your unique healthcare needs. Explore our services below!
        </Text>
        
        <VStack spacing={3} mt={2}>
        <VStack spacing={2} alignItems="center" maxWidth={500}>
            <Image
              width="150px"
              src="./logo2.png"  // Replace with the actual MMC logo URL
              alt="Modern Med Concierge Logo"
            />
            <VStack spacing={1}>
              <Text fontSize="md">
                Modern Med Concierge offers personalized healthcare services designed to meet your individual needs with convenience and care.
              </Text>
              <Button 
                as="a" 
                href="https://modernmedconcierge.com"  // Replace with the actual Modern Med Concierge URL
                colorScheme="blue" 
                size="md"
                target="_blank"
              >
                Visit Modern Med Concierge
              </Button>
            </VStack>
          </VStack>
          
          <VStack spacing={2} alignItems="center" maxWidth={500}>
            <Image
              width="150px"
              src="./logo.png"  // Replace with the actual MMR logo URL
              alt="Modern Med Recovery Logo"
            />
            <VStack spacing={1} >
              <Text fontSize="md">
                Modern Med Recovery specializes in effective addiction treatment and recovery services to help you regain control of your life.
              </Text>
              <Button 
                as="a" 
                href="https://modernmedrecovery.com"  // Replace with the actual Modern Med Recovery URL
                colorScheme="teal" 
                size="md"
                target="_blank"
              >
                Visit Modern Med Recovery
              </Button>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};

export default LandingPage;
