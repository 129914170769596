import React from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  VStack,
  theme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import LandingPage from './components/LandingPage';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box
        textAlign="center"
        fontSize="xl"
        bgGradient="linear(to-r, blue.200, teal.500)"  // Soft gradient background
        minH="100vh"
        p={3}
      >
        <Grid minH="100vh">
          
          <VStack spacing={8}>
            <LandingPage />
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
